define(['app'], function (app) {

  const productPlatform = () => {
    const component = {};

    const _config = {
      channels: {
        update: 'productPlatform/update'
      },
      attrs: {
        shouldUpdate: 'data-update'
      }
    };

    const _init = (element, userDriven) => {
      component.element = element;
      component.shouldUpdate = component.element.getAttribute(component.config.attrs.shouldUpdate);

      if (!userDriven) {
        app.subscribe(component.config.channels.update, component.getPlatformType);
      }

      return component;
    };

    const _getPlatformType = function (productId) {
      if (component.shouldUpdate === 'true') {
        app.ajax.get({
          url: `/${productId}.productPlatform`,
          success: component.successHandler,
          error: component.errorHandler
        });
      }
    };

    const _successHandler = function (response) {
      let parent = component.element.parentNode;
      parent.innerHTML = response;
      let element = parent.querySelector('[data-component=productPlatform]');
      component.init(element, true);
    };

    const _errorHandler = function () {
      console.error(
        'ERROR: Could not retrieve new productPlatform information');
    };

    component.config = _config;
    component.init = _init;
    component.successHandler = _successHandler;
    component.errorHandler = _errorHandler;
    component.getPlatformType = _getPlatformType;

    return component;
  };

  return productPlatform;
});
